<template>
  <div class="container">
    <img
      class="top"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/yunmobile_GuangTong/bg_01.jpg"
    />
    <div class="box">
      <img
        class="card"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/yunmobile_GuangTong/bg_02.jpg"
      />
    </div>
    <div class="form">
      <van-field
        v-model="form.phone_number"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />

      <van-field
        v-model="form.phone_code"
        type="number"
        center
        maxlength="6"
        autocomplete="one-time-code"
        placeholder="请输入验证码"
        class="input-code"
      >
        <template slot="right-icon">
          <div class="code" v-show="sendAuthCode" @click="getAuthCode">
            获取验证码
          </div>
          <div class="code" v-show="!sendAuthCode">
            <span>{{ auth_time }}秒</span>重新获取
          </div>
        </template>
      </van-field>
      <img
        class="submit"
        @click="onSubmit"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/yunmobile_GuangTong/btn.png"
      />
    </div>
    <div class="info">
      <img
        class="title" @click="dialogInfo"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/yunmobile_GuangTong/rulebtn.png"
      />
      <div class="text">1.活动对象:<br>2G/3G/4G/5G手机用户。</div>
      <div class="text">
        本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗。</div>
      <div class="text"> 2.活动内容：</div>
      <div class="text example">
        订购移动云手机，36元/月包含每30天可领云手机专业版以及热门会员N选1。</div>
      <div class="text">
        3.生效方式:
      </div>
      <div class="text">订购立即生效，费用一次性扣除。</div>
      <div class="text">
        4.会员权益领取：
      </div>
      <div class="text">会员权益30天内限领1次，逾期作废。会员权益兑换方式: 点击进入会员中心，选择喜爱的月会员进行兑换，登录对应平台即可使用。</div>

      <div class="text">
        5.会员权益范围：</div>

      <div class="text">包括爱奇艺月卡、腾讯视频月卡、咪咕视频月卡、优酷月卡、芒果月卡、哔哩哔哩月卡、喜马拉雅月卡、QQ音乐月卡、网易云音乐，权益内容可能有调整，请以领取页面为准。</div>
      <div class="text">
        6.温馨提示：
      </div>
      <div class="text">使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵
权赔偿等。</div>
      <div class="text">
       7.如有疑问可致电客服热线：4001189599</div>

      <div class="text">8、本产品以及特权会根据实际运营情况适时调整，具体请以此特权领取页面实际显示及各权益提供方最新权益使用规则为准，该活动的解释权在法律规定的范围内归运营方所有。</div>
      
    </div>
  </div>
</template>

<script>
import { code, codeDlipayPay } from "@/api/carOwner_zhengxing/receive.js";
export default {
  data() {
    return {
      form: {
        phone_number: "",
        phone_code: "",
      },
      sendAuthCode: true,
      auth_time: 0,
      state: false,
      visibility: false,
    };
  },
  methods: {
    // 验证码
    async getAuthCode() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone_number } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await code({ phone_number });
      this.$toast.clear();
      if (res.code != 0) {
        this.$dialog.alert({ message: res.message });
      } else {
        // 设置倒计时秒
        this.sendAuthCode = false;
        this.auth_time = 60;
        let auth_timetimer = setInterval(() => {
          this.auth_time--;
          if (this.auth_time <= 0) {
            this.sendAuthCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      }
    },
    async onSubmit() {
      let filtercode = /^\d{6}$/;

      const { phone_number, phone_code } = this.form;
      if (!filtercode.test(phone_code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const res = await codeDlipayPay({
          phone_number,
          phone_code,
          item_name: "热门会员",
        });
        this.$toast.clear();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message,
          });
        } else {
          location.href = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(
            res.sign_str
          )}`;
        }
      }
    },
    openRules(){

    },
    dialogInfo() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "活动规则",
        
        message: `1、活动对象：2G/3G/4G/5G手机用户
本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!
为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗。
2.活动内容：
订购移动云手机，36元/月包含每30天可领云手机专业版以及热门会员N选1
2.生效方式：
订购立即生效，费用一次性扣除。
3.会员权益领取：
会员权益30天内限领1次，逾期作废。会员权益兑换方式: 点击进入会员中心，选择喜爱的月会员进行兑换，登录对应平台即可使用;
4会员权益范围：
包括爱奇艺月卡、腾讯视频月卡、咪咕视频月卡、优酷月卡、芒果月卡、哔哩哔哩月卡、喜马拉雅月卡、QQ音乐月卡、网易云音乐，权益内容可能有调整，请以领取页面为准。
5、温馨提示：
使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协议规定，运营商和联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵
权赔偿等。
6、如有疑问可致电客服热线：4001189599
7、本产品以及特权会根据实际运营情况适时调整，具体请以此特权领取页面实际显示及各权益提供方最新权益使用规则为准，该活动的解释权在法律规定的范围内归运营方所有。`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #22a0fe;
  .top,
  .card {
    width: 100%;
  }

  .box {
    padding: 0 25px;
    margin-top: -590px;
    // margin-bottom: 60px;

    .card {
      padding-bottom: 33px;
    }
  }

  .form {
    background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/yunmobile_GuangTong/bg_03.jpg")
      center center/contain no-repeat;
    height: 604px;
    width: 750px;
    padding-top: 10px;
    box-sizing: border-box;
    overflow: hidden;
    ::v-deep .van-cell {
      width: 610px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      border: solid 2px #819cbe;
      margin: 100px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 23px;

      &.input-code {
        margin-top: 20px;

        .code {
          width: 190px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: #fff;
          font-size: 28px;
          font-weight: normal;
          background-image: linear-gradient(to right, #9bb3fd ,#66dcfe);
          border-left: solid 2px #819cbe;
        }
      }

      .van-field__body {
        height: 100%;

        input {
          height: 100%;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        color: #006dbf;
        font-weight: normal;
        &::-webkit-input-placeholder {
          font-size: 34px;
          font-weight: 40;
          color: #a4a9b7;
        }
      }
    }

    .submit {
      width: 572px;
      display: block;
      margin: 20px auto 0;
    }

    .checked {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      padding: 10px 0 300px;

      ::v-deep .van-checkbox {
        width: auto;
        .van-checkbox__icon .van-icon{
          border: 1px solid #006dbf;
        }

        &__label {
          margin-left: 4px;
          color: #006dbf !important;

          .book {
            color: #fdff5d;
          }
        }
      }
    }
  }

  .info {
    padding: 66px 26px 160px;

    .title {
      width: 224px;
      display: block;
      margin: 0 auto;
      padding-bottom: 32px;
    }
    .text {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #fff;
    }
    .example {
      font-size: 22px;
      letter-spacing: 0px;
      color: #fff;
    }
  }
}
</style>